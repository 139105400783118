import gql from 'graphql-tag'

const CloneApplicationBase = gql`
  mutation cloneApplicationBase(
    $id: ID!
    $brand: StoreBrand!
    $suppressedBrand: StoreBrand
    $suppressedChannel: ApplicationChannel
  ) {
    cloneApplicationBase(
      id: $id
      brand: $brand
      suppressedBrand: $suppressedBrand
      suppressedChannel: $suppressedChannel
    ) {
      id
      source
      applicationType
      stateCode
      campaignId
      ipAddress
      status
      channel
      preferredProductCode
      suppressedBrands
      userInformation {
        email
      }
      customerInformation {
        personalInformation {
          firstName
          lastName
          dateOfBirth
          documentType
          documentNumber
          stateIdType
          driverLicenseNumber
          driverLicenseStateCode
          stateIdExpirationDate
        }
        contactInformation {
          address {
            addressLine1
            addressLine2
            cityName
            stateCode
            zipCode
          }
          phones {
            type
            number
            extension
            phonePreference
          }
          emails {
            type
            email
            verified
          }
        }
        additionalInformation {
          monthsAtAddress
          ownResidence
        }
        spousalInformation {
          firstName
          lastName
          email
          phoneNumber
        }
        bankingInformation {
          isReadOnly
          routingNumber
          accountNumber
          accountHoldersName
          accountBalance
          accountOpenDate
        }
        incomeInformation {
          type
          employmentInformation {
            name
          }
          employmentHireDate
          incomeWithholding
          jobTitle
          paymentFrequency
          paymentMethod
          amountGross
          amountNet
          nextPaymentDate
          paymentDayOfWeek
          jobShift
        }
      }
      storeInformation {
        storeNumber
        brandName
      }
      loanApplication {
        additionalAttributes {
          optOut
          canShareInfo
          smsOptIn
          otpResponse
          iovationBlackBox
          flagAutoOrigination
          disclosures {
            idTag
            value
          }
        }
      }
      loanApplicationProgress {
        applicationUrl
      }
      createdAt
      modifiedAt
    }
  }
`

const ConvertApplicationTypeToConversionMutation = gql`
  mutation convertApplicationTypeToConversion(
    $id: ID!
    $loanApplicationDetails: LoanApplicationDetailsInput
  ) {
    convertApplicationTypeToConversion(
      id: $id
      loanApplicationDetails: $loanApplicationDetails
    ) {
      id
      applicationType
      channel
      preferredProductCode
      userInformation {
        email
      }
      storeInformation {
        storeNumber
        brandName
      }
      loanApplicationProgress {
        applicationUrl
      }
      initialApplicationDetails {
        stateCode
        product {
          productCode
          channel
        }
      }
      conversionDetails {
        loanNumber
        productCode
        payoffAmount
      }
      status
    }
  }
`

const createApplicationMutation = gql`
  mutation createApplication(
    $application: ApplicationCreateInput!
    $cmsData: String
  ) {
    create(application: $application, cmsData: $cmsData) {
      id
      applicationType
      channel
      stateCode
      status
      customerInformation {
        personalInformation {
          firstName
          lastName
        }
      }
      userInformation {
        email
      }
      loanApplicationProgress {
        applicationUrl
      }
      storeInformation {
        storeNumber
        brandName
      }
    }
  }
`

const CreateConversionDenialMutation = gql`
  mutation createConversionDenial($id: ID!) {
    createConversionDenial(id: $id) {
      id
    }
  }
`

const CreateReturningCustomerApplicationMutation = gql`
  mutation createReturningApplication(
    $applicationType: ApplicationType
    $brandName: String,
    $isGPCEnabled: Boolean,
    $loanApplicationDetails: LoanApplicationDetailsInput
  ) {
    createReturningApplication(
      applicationType: $applicationType
      brandName: $brandName
      isGPCEnabled: $isGPCEnabled
      loanApplicationDetails: $loanApplicationDetails
    ) {
      id
      applicationType
      channel
      status
      stateCode
      customerInformation {
        personalInformation {
          firstName
          lastName
          documentType
          documentNumber
          dateOfBirth
          stateIdType
          driverLicenseStateCode
          driverLicenseNumber
        }
        contactInformation {
          phones {
            type
            number
            extension
            phonePreference
          }
          emails {
            type
            email
            verified
          }
          address {
            addressLine1
            addressLine2
            cityName
            zipCode
            stateCode
          }
        }
        additionalInformation {
          monthsAtAddress
          ownResidence
        }
      }
      userInformation {
        email
      }
      loanApplicationProgress {
        applicationUrl
      }
      storeInformation {
        storeNumber
        brandName
      }
    }
  }
`

const PrepareForEnrollmentMutation = gql`
  mutation prepareForEnrollment($applicationId: ID!) {
    prepareForEnrollment(applicationId: $applicationId)
  }
`

const SaveAgreementsMutation = gql`
  mutation saveAgreements($id: ID!, $agreements: AgreementsInput!, $repaymentMethod: String) {
    saveAgreements(id: $id, agreements: $agreements, repaymentMethod: $repaymentMethod) {
      id
      customerInformation {
        spousalInformation {
          firstName
          lastName
          email
          phoneNumber
        }
      }
      loanApplication {
        additionalAttributes {
          disclosures {
            idTag
            value
          }
        }
      }
      loanApplicationProgress {
        applicationUrl
      }
    }
  }
`

const SaveBankInformationMutation = gql`
  mutation saveBankInformation($id: ID!, $bankInfo: BankInformationInput!) {
    saveBankInformation(id: $id, bankInformation: $bankInfo) {
      id
      customerInformation {
        bankingInformation {
          isReadOnly
          routingNumber
          accountNumber
          accountHoldersName
          accountOpenDate
          accountBalance
        }
      }
      loanApplicationProgress {
        applicationUrl
      }
    }
  }
`

const SaveBankVerificationDecision = gql`
  mutation saveDecision($id: ID!, $decision: BankVerificationDecisionInput!) {
    saveDecisionBankVerification(id: $id, decision: $decision) {
      requestCode
      siteResult
      decisionResult
    }
  }
`

const SaveIncomeInformationMutation = gql`
  mutation saveIncomeInformation(
    $id: ID!
    $incomeInfo: FormIncomeInformationInput!
    $agreements: IncomeAgreementInput
  ) {
    saveIncomeInformation(
      id: $id
      incomeInformation: $incomeInfo
      agreements: $agreements
    ) {
      id
      customerInformation {
        contactInformation {
          phones {
            type
            number
            extension
            phonePreference
          }
        }
        incomeInformation {
          type
          employmentHireDate
          jobTitle
          paymentFrequency
          paymentMethod
          amountGross
          amountNet
          nextPaymentDate
          jobShift
          employmentInformation {
            name
          }
        }
      }
      loanApplication {
        additionalAttributes {
          disclosures {
            idTag
            value
          }
        }
      }
      loanApplicationProgress {
        applicationUrl
      }
      invalidPrecheck
    }
  }
`

const SavePersonalInformationMutation = gql`
  mutation savePersonalInformation(
    $id: ID!
    $personalInfo: FormPersonalInformationInput!
  ) {
    savePersonalInformation(id: $id, personalInformation: $personalInfo) {
      id
      stateCode
      channel
      customerInformation {
        personalInformation {
          firstName
          lastName
          documentType
          documentNumber
          dateOfBirth
          stateIdType
          driverLicenseStateCode
          driverLicenseNumber
          stateIdExpirationDate
        }
        contactInformation {
          phones {
            type
            number
            extension
            phonePreference
          }
          emails {
            type
            email
            verified
          }
          address {
            addressLine1
            addressLine2
            cityName
            zipCode
            stateCode
          }
        }
        additionalInformation {
          monthsAtAddress
          ownResidence
        }
      }
      loanApplicationProgress {
        applicationUrl
      }
      invalidPrecheck
    }
  }
`

const SaveStoreInformationMutation = gql`
  mutation saveStoreInformation(
    $id: ID!
    $storeInfo: StoreInformationInput!
    $loanProgress: LoanApplicationProgressInput!
  ) {
    saveStoreInformation(
      id: $id
      storeInformation: $storeInfo
      loanApplicationProgress: $loanProgress
    ) {
      id
      storeInformation {
        storeNumber
        brandName
      }
      loanApplicationProgress {
        applicationUrl
      }
    }
  }
`

const SaveUserInformation = gql`
  mutation saveUserInformation(
    $id: ID!
    $userInformation: UserInformationInput!
  ) {
    saveUserInformation(id: $id, userInformation: $userInformation) {
      id
    }
  }
`

const SubmitApplicationMutation = gql`
  mutation submitApplication($id: ID!) {
    submitApplication(id: $id) {
      id
      applicationState
    }
  }
`

const WithdrawOpenApplicationMutation = gql`
  mutation withdrawApplication($id: ID!) {
    withdrawApplication(id: $id) {
      id
      userInformation {
        email
      }
    }
  }
`

const UpdateDebitCardAttemptsMutation = gql`
  mutation updateDebitCardAttempts($id: ID!, $attempts: Int!) {
    updateDebitCardAttempts(id: $id, attempts: $attempts) {
      id
    }
  }
`

export default {
  createApplicationMutation,
  CreateConversionDenialMutation,
  CreateReturningCustomerApplicationMutation,
  PrepareForEnrollmentMutation,
  SaveUserInformation,
  SavePersonalInformationMutation,
  SaveIncomeInformationMutation,
  SaveBankInformationMutation,
  SaveAgreementsMutation,
  SaveStoreInformationMutation,
  SaveBankVerificationDecision,
  CloneApplicationBase,
  WithdrawOpenApplicationMutation,
  UpdateDebitCardAttemptsMutation,
}
export {
  CloneApplicationBase,
  ConvertApplicationTypeToConversionMutation,
  createApplicationMutation,
  CreateConversionDenialMutation,
  CreateReturningCustomerApplicationMutation,
  PrepareForEnrollmentMutation,
  SaveAgreementsMutation,
  SaveBankInformationMutation,
  SaveBankVerificationDecision,
  SaveIncomeInformationMutation,
  SavePersonalInformationMutation,
  SaveStoreInformationMutation,
  SaveUserInformation,
  SubmitApplicationMutation,
  WithdrawOpenApplicationMutation,
  UpdateDebitCardAttemptsMutation,
}
